<template>
 <div class="v_register">
   <h3 class="v-register-title g-flex-align-center g-flex-justify-center">忘记密码</h3>
   <div class="v-register-form g-flex-column">

     <div class="v-register-form-item g-flex-column">
       <div class="v-register-form-item-title g-flex-align-center">
        <span>手机号</span>
       </div>
       <div class="v-register-form-item-input v-register-form-item-code g-flex-align-center">
        <van-field type="tel" v-model="form.mobile"  placeholder="请输入手机号"/>
        <div class="v-register-code-btn g-flex-align-center g-flex-justify-center" @click="getCode">{{sendCodeText}}</div>
       </div>
     </div>

    <div class="v-register-form-item g-flex-column">
       <div class="v-register-form-item-title g-flex-align-center">
        <span>验证码</span>
       </div>
       <div class="v-register-form-item-input g-flex-align-center">
        <van-field type="tel" v-model="form.mobile_code" placeholder="请输入验证码"/>
       </div>
     </div>
     <div class="v-register-form-item g-flex-column">
       <div class="v-register-form-item-title g-flex-align-center">
        <span>密码</span>
       </div>
       <div class="v-register-form-item-input v-register-form-item-pwd">
        <van-field :type="pwdType" v-model="form.password"  placeholder="请输入密码"/>
        <i class="iconfont" :class="pwdType ==='password' ? 'iconbukejian1': 'iconmimakejianguanbi'" @click="pwdTypeClick"></i>
       </div>
     </div>
     <div class="v-register-form-item g-flex-column">
       <div class="v-register-form-item-title g-flex-align-center">
        <span>确认密码</span>
       </div>
       <div class="v-register-form-item-input v-register-form-item-pwd">
          <van-field :type="pwdSecondType" v-model="form.password_two"  placeholder="请再次输入密码"/>
          <i class="iconfont" :class="pwdSecondType ==='password' ? 'iconbukejian1': 'iconmimakejianguanbi'" @click="pwdTypeSecondClick"></i>
       </div>
     </div>

    <div class="v-register-btn g-flex-justify-between">
      <operation-button :isPadding="false" :buttonObj="buttonObj" @clickItem="emitregisterClick"/>
    </div>

    <div class="v-register-tips-list g-flex-justify-between">
      <div class="v-register-tips-list-item g-flex-align-center" @click="$router.replace({ name: 'login' })">
        <span>去登录</span>
      </div>
    </div>
   </div>
 </div>
</template>

<script>
import OperationButton from '../../components/OperationButton.vue'
import { apiForgetPassword, apiSendCode } from '@/utils/api.js'
export default {
  components: { OperationButton },
  data() {
    return {
      pwdType: 'password',
      pwdSecondType: 'password',
      timer: 0,
      sendCodeText: '获取验证码',
      defaultCodeText:'重新获取',
      form: {
        mobile: '',
        password: '',
        mobile_code: '',
        password_two: ''
      },
      
      buttonObj: {
        title: '确认',
        claSS: 'c-btn-default c-btn-border-radius',
        event: 'apiForgetPasswordHandel'
      }
    }
  },
  methods: {
    // 登录按钮触发
    emitregisterClick(event) {
      if(!event) return
      this[event]()
    },
    pwdTypeClick() {
      if(this.pwdType === 'password') return this.pwdType = 'text'
      return this.pwdType = 'password'
    },
    pwdTypeSecondClick() {
      if(this.pwdSecondType === 'password') return this.pwdSecondType = 'text'
      return this.pwdSecondType = 'password'
    },

    // 获取验证码
    getCode() {
      if(!(/^1\d{10}$/.test(this.form.mobile))) return this.$toast('手机号码有误，请重试'); 
      if(this.timer) return
      this.apiSendCodeHandel()
    },
    // 发送验证码
    async apiSendCodeHandel() {
      this.$global.loadingShow = true
      const { success, data } = await apiSendCode({ mobile: this.form.mobile })
      if(!success) return
      let timeNum = 60
      this.$toast(data.msg)
      this.timer = setInterval(() => {
        timeNum --
        this.showNum = timeNum === 0 ? false : true
        this.sendCodeText = timeNum === 0 ? this.defaultCodeText : timeNum + 's'
        if(timeNum === 0) {
          clearInterval(this.timer)
          this.timer = 0
        }
      }, 1000)
    },

    // 确认修改
    async apiForgetPasswordHandel() {
      if(!(/^1\d{10}$/.test(this.form.mobile))) return this.$toast('手机号码有误，请重试'); 
        if(!this.form.password) return this.$toast('密码不能为空')
        if(this.form.password !== this.form.password_two) return this.$toast('请确认两次密码输入是否一致')
        if(!this.form.mobile_code) return this.$toast('请输入验证码')
        this.$global.loadingShow = true
        const { success, data } = await apiForgetPassword(this.form)
        if(!success) return
        this.$toast(data.msg)
        setTimeout(() => {
          this.$router.replace({ name: 'login' })
        }, 500);
    }
  }
}
</script>

<style lang='scss'>
.v_register {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: url('/image/daili_login_bg.png') no-repeat;
  background-size: cover;
  background-position: center;

  .v-register-title {
    padding-top: 80px;
    color: $main_color;
    font-size: 24px;
    letter-spacing: 1px;
    font-weight: bold;
  }

  .v-register-form {
    padding: 30px 46px 20px 46px;

    .v-register-form-item {
      background: transparent;
      margin-top: 24px;
      &:nth-of-type(1) {
        margin-top: 0;
      }
      .v-register-form-item-title {
        padding-bottom: 15px;
        span {
          color: #246EC8;
          font-size: 16px;
          font-weight: bold;
        }
      }

      .v-register-form-item-input {
        border-bottom: 1px solid #DCDCDC;
        // 发送验证码input
        &.v-register-form-item-code {
          .van-field {
            flex: 1;
          }
        }
        // 密码
        &.v-register-form-item-pwd {
          position: relative;
          .iconfont {
            position: absolute;
            right: 0;
            font-size: 24px;
            padding: 5px;
            top: -2px;
            color:  #333333;
          }
        }

        // 输入框样式更改
        .van-cell {
          padding: 0;
          padding-bottom: 12px;
          border: none;
          background: transparent;
          &::after {
            border-bottom: none;
          }
          input {
            font-size: 16px;
            &::-webkit-input-placeholder {
              color: #CACACA;
              font-size: 16px;
            }
          }
          .van-field__body {
            .van-field__control {
              color: #333333;
            }
            .van-icon-clear {
              color:  #333333;
              font-size: 18px;
            }
          }
        }

        // 发送验证码按钮
        .v-register-code-btn {
          width: 80px;
          height: 24px;
          line-height: 24px;
          background: $white;
          color: $main_color;
          border: 1px solid $main_color;
          border-radius: 15px;
          font-size: 12px;
          margin-bottom: 12px;
        }
      }
    }

    .v-register-btn {
      margin-top: 28px;
    }

    .v-register-tips-list {
      padding-top: 20px;
      .v-register-tips-list-item {
        color: $main_color;
        font-size: 14px;
        padding: 8px 5px;
      }
    }
  }


}
</style>